@import "../variables.module";

.sound-setting {
  border: 2px solid $fontColorTwo;

  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;

  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1a1d3d;

  svg {
    width: 15px;
    height: auto;
    path {
      fill: #fff;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
