@import "../variables.module";

.no-width {
  width: auto !important;
}

.value-icon {
  width: 40px;
  display: inline-block;

  &.large {
    width: 18px;
  }
  &.mid {
    width: 15px;
  }
  &.small {
    width: 20px;
  }

  &.has-glow {
    filter: drop-shadow(0 0 10px rgba(92, 180, 255, 0.5));
    svg {
      fill: #228de9;
      path {
        fill: #228de9;
      }
    }
  }

  &.no-glow {
    filter: none;
  }

  &.has-custom-color {
    svg {
      width: 100%;
      fill: #228de9;
      path {
        fill: #228de9;
      }
    }
  }
}

.default-icon {
  display: flex;
  width: 100%;
  filter: drop-shadow(0 0 10px rgba(92, 180, 255, 0.5));
}
