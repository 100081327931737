@import "../variables.module";

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;

  >* {
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 52px;
    border-radius: $radius;

    // every other row
    &:nth-child(even) {
      background: #24294daa;
    }

    // starting from the second row
    &:nth-child(n+2) {
      color: white;
      font-weight: 500;
      cursor: pointer;
    }

    >* {
      width: var(--width);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .head {
    font-weight: 400;
    color: $fontColorSeven;
  }
}
