@import "../variables.module";

/* HeaderPopup.module.css */
.container {
  position: relative;
  display: inline-block;
}

/* ToolTip.module.scss */
.tippy-container {
  border-radius: 6px;
  min-width: 100px;
  max-width: 300px;
  color: #e8e5ff;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-wrap: break-word;

  &.big {
    padding: 10px 20px;
    font-size: 15px;
  }

  &.small {
    padding: 6px 12px;
    min-width: 40px;
    font-size: 13px;
  }

  &.error-tooltip {
    color: $popupRed;
  }

  @media screen and (max-width: 769px) {
    max-width: 200px;
  }
}

.custom-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #212749; // Arrow color same as tooltip background
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  top: -5px;
  left: calc(50% - 5px); // Center the arrow horizontally
}

.tooltip {
  visibility: hidden;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 5;
  top: 50%;
  width: 100px;
  padding: 10px;
  transform: translateY(-50%);
  left: 100%;
  // margin-left: -60px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  font-family: $boldFont;
  font-size: 15px;
}

//Rest of the tippy styling is in global.scss
.container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.flyout-container {
  // height: 400px;
  min-height: 200px;
  max-height: 400px;
  z-index: 1000;
  width: 200px;
  overflow: auto;
  @include scrollbars(3px, $btnFill, transparent);

  .inner-flyout-container {
    padding-top: 5px;
    padding-right: 4px;
    padding-bottom: 6px;
    padding-left: 6px;
    top: 18px;
    right: 2px;
    left: 6px;
    bottom: 0;
    overflow-y: auto;
    background-color: #171d3b;
    &.side-menu-nav {
      background-color: black;
      // .icon-container {
      // }
    }
  }
}
