@import "../variables.module";

.blog-card-container {
  display: flex;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;

  span {
    height: 250px;
  }

  .blog-card-image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
  }

  .blog-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #191f3b;
    padding: 30px 15px;

    h2 {
      font-weight: 600;
    }
  }

  .blog-title {
    color: white;
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
  }

  .blog-preview {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    h3 {
      color: #fff;
      // font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      overflow: hidden;
      color: rgba(232, 229, 255, 0.75);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      align-self: stretch;
    }

    .blog-poste-date {
      color: rgba(232, 229, 255, 0.5);
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }
}

.blog-page-container {
  margin-top: 20px;
  min-height: 700px;
  max-width: 900px;
  margin: 0 auto;

  &__wide {
    max-width: 1000px;
    margin-top: 25px;
  }

  &.mobile-three {
    padding: 0px 00px;
  }

  @media (max-width: $breakPointOne) {
    padding: 0px 0px;
    min-height: auto;
  }

  .created-time {
    font-size: 14px;
    color: #c1c4dc;
  }

  a {
    color: $fontColorFour;

    // &:not(.anchor) {
    //   // text-decoration: underline;
    // }
  }

  li {
    ol {
      padding-left: 20px;
    }
  }

  ul {
    padding-left: 25px;
  }

  ul li ul li {
    text-indent: 1em;
  }

  .letter {
    ol {
      list-style-type: upper-alpha;
    }
  }

  h1 {
    font-size: 22px;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 1.5rem;
    text-align: center;
    color: white;
  }

  h2 {
    font-size: 18px;
    margin-top: 2rem;
  }

  h3 {
    font-size: 18px;
    color: #c1c4dc;
    margin-top: 2rem;
  }

  h4,
  h5,
  h6 {
    font-size: 16px;
    margin-top: 2rem;
    color: #c1c4dc;
  }

  code,
  pre {
    margin-top: 15px;
    border-radius: $radius;
    word-wrap: break-word;
    background: #12172f;
    color: #9aa0c1;
    padding: 20px 25px;
    font-size: 14px;
    line-height: 1.5em;
    font-family: monospace;
    letter-spacing: -0.06em;
  }

  p,
  li {
    color: #b2b1c9;
  }

  p {
    font-size: 14px;
    line-height: 1.5em;
    margin-top: 15px;
  }

  li {
    line-height: 1.5em;
    font-size: 14px;
    margin-top: 15px;
  }

  &.promo {
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: circle;
  }

  ul,
  ol {
    margin: 0;
    margin-top: 15px;
  }

  /* If you want to align the list items with other text or elements */
  ul li,
  ol li {
    text-align: left;
    margin-left: 1rem;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  table {
    width: 100% !important;
    margin-top: 20px;
    margin-bottom: 20px;

    tr {
      display: table-row;
      vertical-align: middle;
      // border-bottom: 1px solid #5b628c;
      font-family: $primaryFont;
      color: #c1c4dc;

      height: 28px;
      border-radius: 6px;

      &:last-child {
        border-bottom: none;
      }

      td {
        padding: 12px 15px;
        display: table-cell;
        text-align: left;
        vertical-align: middle;
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }

      &:nth-of-type(odd) {
        // background-color: $darkLineColor;
        background-color: #13172f;
      }

      &.space-items {
        padding-bottom: 10px;
      }

      &.heading {
        background-color: transparent;

        > div {
          // color: $fontColorTwo;
          color: #5b628c;
        }
      }

      > div {
        font-family: $primaryFont;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-style: normal;
        // font-weight: 600;
        line-height: normal;

        &:first-child {
          justify-content: flex-start;
          padding-left: 15px;
        }

        &:last-child {
          justify-content: flex-end;
          padding-right: 15px;
        }
      }
    }
  }

  .header-nav {
    margin-bottom: 20px;
  }

  .blog-page-title {
    .meta-info {
      display: flex;
      gap: 20px;
      justify-content: center;

      .section {
        .item-name {
          color: #5b628c;
        }

        span {
          font-size: 14px;
          color: #e8e5ff;
        }
      }
    }

    padding-top: 30px;
    padding-bottom: 60px;
  }

  .breadcrumbs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    line-height: 1.5em;
    // gap: 20px;
    font-size: 14px;
    color: #c1c4dc;
    margin-bottom: 20px;
    text-transform: capitalize;

    svg {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .blog-page-image {
    // background-clip: padding-box;
    aspect-ratio: 3/1;
    // flex: 1 1;
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0px;
  }

  .main-content-section {
    display: flex;
    gap: 40px;
    justify-content: center;
    // overflow-y: hidden;

    .side-nav-container {
      width: 20%;
      transition: opacity 0.3s ease-in-out;

      &.hide {
        opacity: 0;
        pointer-events: none;
      }
    }

    .side-nav {
      margin-top: 15px;
      width: 100%;
      position: sticky;
      top: 1rem;

      a {
        line-height: 1.4em;
        margin-bottom: 20px;
        display: block;
        opacity: 0.5;

        transition:
          opacity 0.2s ease-in-out,
          border-left 0.2s ease-in-out;

        span {
          font-weight: 400;
          color: $primaryFontColor;
          font-size: 14px;
        }

        &.active-anchor {
          border-left: 1px solid $blue;
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .main-content {
      width: 80%;
    }

    .mobile-table-toggle {
      padding: 20px;
      display: block;
      border: none;
      width: 100%;
      display: flex;
      justify-content: space-between;
      display: none;

      span {
        font-size: 14px;
      }

      .arrow {
        transition: transform 0.3s ease-in-out;
        transform: rotate(90deg);

        svg {
          width: 20px;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        .arrow {
          transform: rotate(270deg);
        }

        // display: none;
      }
    }

    @media (max-width: $breakPointOne) {
      flex-direction: column;

      .mobile-table-toggle {
        display: flex;
        background-color: #13172f;
      }

      .main-content {
        width: 100%;
      }

      .side-nav-container {
        left: 0;
        right: 0;
        width: 100%;
        position: fixed;
        background-color: $secondaryBackgroundColor;
        height: auto;
        top: 70px;

        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;

        &.show {
          opacity: 1;
          pointer-events: all;
        }
      }

      .side-nav {
        margin-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0px;
        // border: 1px solid #fff;
        height: 0;
        overflow: hidden;

        a {
          margin: 10px 0px 10px 0px;
        }
      }
    }

    @media (max-width: $breakPointThree) {
      .side-nav-container {
        top: 110px;
      }
    }
  }
}
